import { create } from "@storybook/theming";

export default create({
  // Base Background:
  base: "light",

  // Brand
  brandTitle: "S2A Webcomponents",
  brandUrl: "https://my.share2act-dev.io",
  brandImage:
    "https://assets.share2act-dev.io/images/img/syskron_with_cloud.svg",

  // Typography
  fontBase: '"Noto Sans", sans-serif',
  fontCode: "monospace",
});
